@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	/*	.mainHeader {
		&.affix-top {
			.navbar-area {

				.navbar-toggler {
					color: var(--white) !important;
				}

			}
		}
	}*/



	.banner-forms {
		position: relative;
		min-height: 620px;
		height: 100vh;
		z-index: 3;

		@media (max-width:767px) {
			min-height: 560px;

		}

		@media (max-width:992px) {
			height: calc(100vh - 80px);
		}

		.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;

				@media (max-width:992px) {
					display: none;
				}


				button {
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 1px solid #ddd !important;
					border-radius: 50%;
					box-shadow: 0 0 10px rgba(0, 0, 0, .5);
					background-color: var(--white) !important;
					position: absolute;
					left: 50px;
					font-size: 34px;

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--black);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {
							opacity: .3;
						}
					}

					&.owl-next {
						position: absolute;
						right: 50px;
						left: initial;

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		&:before {
			background: rgb(255, 255, 255);
			background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			top: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		}

		&:after {

			background: rgb(255, 255, 255);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			bottom: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		}

		@media (max-width:992px) {
			height: calc(100vh - 80px);
		}

		.search-form-area {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width:1340px) {
				flex-wrap: wrap;
			}

			@media (min-width:1141px) {
				padding-left: 15px;
			}

			.form-flex {
				padding: 15px 8px 5px;
				width: 100%;


				@media (max-width:1140px) {
					width: 100%;
					padding: 10px 0;
				}

				&:first-child {
					border-left: none;
				}

				.form-bx-area {
					position: relative;
					padding-right: 0;
					padding-left: 2px;
					border-bottom: 2px solid var(--grey);

					&.pinarea {
						@media (min-width:1240px) {
							padding-right: 55px;
							min-width: 270px;
						}
					}

					&.schla {
						@media (min-width:1240px) {
							min-width: 250px;
						}
					}

					@media (max-width:1340px) {
						padding-right: 55px;
					}

					button.form-control {
						min-width: 110px;
						background: transparent;
						z-index: 2;
						position: relative;
						padding-right: 20px;

						@media (max-width:1340px) {
							font-size: 18px;
						}
					}


				}

				.fa,
				.far,
				.fas {
					position: absolute;
					right: 2px;
					top: 44px;
					color: var(--primary);
					z-index: 2;
					font-size: 20px;
					pointer-events: none;

					@media (max-width:1340px) {
						top: 28px;
					}

					@media (max-width:1140px) {
						top: 13px;

					}

					&.shadow {
						font-size: 65px;
						top: 0px;
						color: var(--grey);
						z-index: 1;
						right: 14px;

						@media (max-width:1340px) {
							font-size: 48px;
						}

						@media (max-width:1140px) {
							font-size: 37px;
							top: -4px;
							opacity: .5;
						}
					}

					&.fa-map-marker-alt {
						right: 8px;

						@media (max-width:1140px) {
							right: 0;
						}

						&.shadow {
							@media (max-width:1140px) {
								right: 9px !important;
							}
						}
					}
				}


				.form-control {
					background-color: var(--white);
					border: none;
					border-radius: 0;
					width: 100%;
					padding: 28px 0 15px;
					padding-right: 10px;
					padding-left: 10px;
					height: inherit;
					box-shadow: inherit;
					font-family: var(--dfont2);
					padding: 40px 0 11px 5px;
					font-size: 16px;
					text-align: left;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					cursor: pointer;


					@media (max-width:1340px) {
						font-size: 18px;
						padding: 20px 0 11px;
					}

					@media (max-width:1140px) {
						font-size: 16px !important;
						padding: 10px 0;
					}
				}


			}

			.form-btn {

				@media (max-width:1140px) {
					width: 100%;
					text-align: center;
				}

				.btn {
					align-items: center;
					display: flex;
					justify-content: center;
					font-size: 30px;
					border-radius: 0 8px 8px 0;
					font-family: var(--dfont2);
					font-weight: bold;
					height: 120px;
					position: relative;
					margin-left: 15px;

					@media (max-width:1340px) {
						font-size: 24px;
						height: 100px;

					}


					@media (max-width:1140px) {
						font-size: 16px;
						border-radius: 4px;
						position: relative;
						height: inherit;
					}

					i {
						margin-left: 8px;
						color: rgba(0, 0, 0, .25);
						font-size: 30px;
						margin-right: 12px;

						@media (max-width:1140px) {
							font-size: 16px;
							margin-left: 0;
						}
					}


				}
			}
		}

		.banner-slide {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: -1;

			.owl-dots {
				display: none;
			}

			.owl-item {
				position: relative;
			}

			.item {
				width: 100%;
				min-height: 620px;
				height: 100vh;
				background-size: cover;
				background-position: no-repeat;
				background-position: center;

				@media (max-width:992px) {
					height: calc(100vh - 80px);
				}

				@media (max-width:767px) {
					min-height: 560px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&.banner1 {
					background-image: url(RESOURCE/img/index-slider-01.jpg);
					/*
					@media (max-width:992px) {
						background-image: url(RESOURCE/img/index-slider-01.jpg);
					}*/
				}

				&.banner2 {
					background-image: url(RESOURCE/img/banner1new.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner1new.Xc_992x1042.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/banner2new.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner2new.Xc_992x1042.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/banner3new.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner3new.Xc_992x1042.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/banner4new.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner4new.Xc_992x1042.jpg);
					}
				}

				&.banner6 {
					background-image: url(RESOURCE/img/banner5new.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/banner5new.Xc_992x1042.jpg);
					}
				}

				&:after {
					height: 80px;
					bottom: 0;
					left: 0;
					right: 0;
					position: absolute;
					display: block;
					background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
					content: "";
					opacity: 0.6;
				}
			}


			.owl-dots {
				bottom: 60px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				@media (max-width:992px) {
					display: none;
				}

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 4px solid var(--white);
					margin: 0 4px;

					&.active {
						border-bottom-color: var(--primary);
					}
				}
			}
		}


		.search-box-home {
			position: absolute;
			bottom: 85px;
			text-align: center;
			width: 100%;
			z-index: 5;
			padding: 0 50px;

			@media (max-width:1140px) {
				bottom: 18px;
				padding: 0 20px;
			}
		}

		.searchbar {

			display: inline-block;
			text-align: left;
			background-color: var(--white);
			border-radius: 8px;
			width: 100%;
			max-width: 960px;

			@media (max-width:1140px) {
				width: 100%;
				padding: 10px 20px 10px;
			}

			/*@media (min-width:993px) {
				max-width: 1200px;
				width: 100%;
			}*/


			.searchbox {
				padding: 0;
				position: relative;
				right: -1px;


				@media (max-width:1140px) {
					padding-top: 10px;
					display: inline-block;
					right: 0;
				}

				input,
				.search-btn,
				select {
					border-radius: 0;
				}

				.search-btn {
					width: 100%;
				}

				.search-popup-trigger {
					cursor: pointer;
				}

				.form-control:disabled,
				.form-control[readonly] {
					background-color: white;
				}
			}


		}

		.contact-section {
			background-color: #fff !important;
		}






		@media (max-width:767px) {

			.iheader {
				background: var(--introMobileImg) no-repeat center;
				-webkit-background-size: cover;
				background-size: cover;

				.intro {
					.hero {
						h2 {
							font-size: 18px;
						}
					}
				}


			}
		}


	}



}