:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/bg-menu-green.jpg);
	--navbarbreak: 1199px;
	--primary: #274073;
	--secondary: #4D6E96;
	--black: #000000;
	--d-font: 'DINRoundPro', sans-serif;
	--d-font2: 'DINRoundPro-Medi', sans-serif;
	--white: #fff;
	--d-blue: #06235D;
	--d-blue2: #4D6E96;
	--h1-font: 'Julius Sans One', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #025a84;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #06235D;
	--li-blue: #daf0fb;
	--grey2: #7b7b7b;
	--red: #f55454;
	--grey3: #999999;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #f9f9f9;
	--cal_not_available: #DC0000;

	/* Calendar colors */
	--cal_not_available: #cd7898;
	--disabled: rgba(86, 90, 92, .5);
	--available: #fff;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;



}